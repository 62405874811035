import { isString, isObject } from 'lodash';

export const updateFieldTypeBasedOnContent = obj => {
  if (!isObject(obj) || !isString(obj.content)) {
    return obj;
  }

  const match = obj.content.match(/^(#+)(.*)$/);

  if (match) {
    const level = match[1].length;
    const text = match[2].trim();

    let fieldType;
    if (level === 1) {
      fieldType = 'heading1';
    } else if (level === 2) {
      fieldType = 'heading2';
    } else {
      fieldType = 'heading3';
    }

    return { fieldType, content: text };
  }

  return obj;
};

export const supportLineBreak = children => {
  if (typeof children === 'string') {
    return children.split('<br>').join('\n');
  }

  return children;
};

export const splitCta = obj => {
  if (!obj || typeof obj !== 'object') return obj;

  const hrefs = obj.href.includes(' | ') ? obj.href.split(' | ').map(s => s.trim()) : [obj.href];
  const contents = obj.content.includes(' | ')
    ? obj.content.split(' | ').map(s => s.trim())
    : [obj.content];

  if (hrefs.length === 1 && contents.length === 1) {
    return [obj];
  }

  if (hrefs.length !== contents.length) {
    return [obj];
  }

  return hrefs.map((href, index) => ({
    fieldType: obj.fieldType,
    href,
    content: contents[index],
  }));
};

export const splitTitle = obj => {
  if (!obj || typeof obj !== 'object' || !obj.content) return obj;

  const contents = obj.content.includes(' | ')
    ? obj.content.split(' | ').map(s => s.trim())
    : [obj.content];

  if (contents.length === 1) {
    return [obj];
  }

  return contents.map((content, index) => ({
    fieldType: `heading${index + 2}`,
    content: content,
  }));
};

export const dividerPosition = obj => {
  if (!obj || typeof obj !== 'object' || !obj.content) return null;

  const str = obj.content;
  const trimmedStr = str.trim();

  if (trimmedStr.startsWith('[divider]')) {
    return 'start';
  }

  if (trimmedStr.endsWith('[divider]')) {
    return 'end';
  }

  return null;
};

export const isReverseSection = obj => {
  if (!obj || typeof obj !== 'object' || !obj.content) return null;

  const str = obj.content;
  const trimmedStr = str.trim();

  return trimmedStr.startsWith('[reverse]');
};

export const removeBracketsFromContent = obj => {
  if (!obj || typeof obj !== 'object' || !obj.content) return obj;

  return {
    ...obj,
    content: obj.content.replace(/\[.*?\]/g, '').trim(),
  };
};
